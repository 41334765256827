import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Typography } from '@mui/material'
import { Download, NavigateBefore, NavigateNext, UnfoldLessDouble, UnfoldMoreDouble } from '@mui/icons-material'
import AccountBalanceTable from './AccountBalanceTable'
import axios from 'axios'
import PreLoader from '../../PreLoader'
import { utils, writeFile } from 'xlsx'

export type Grade = {
  fk_id_grade: number | null, // After project of implementing dbo.student_grade, this won't be nullable
  grade_name: string | null // After project of implementing dbo.student_grade, this won't be nullable
}

export type Affiliation = {
  fk_id_affiliation: number,
  affiliation_name: string,
  grades: Grade[]
}

export type ViewAccountBalance = {
  fk_id_affiliation: number,
  affiliation_name: string,
  year: number,
  month: number,
  fk_id_grade: number,
  grade_name: string,
  students_per_grade: number,
  fk_id_currency: number,
  currency_name: string,
  total_gross_value: number,
  total_due_value: number,
  total_paid_value: number | null,
}

export type TotalTypes = {
  total_gross_value: number,
  total_due_value: number,
  total_paid_value: number | null
}

type TotalPerGrade = {
  fk_id_grade: number,
  grade_name: string,
  fk_id_currency: number,
  year: TotalTypes,
  term: {
    spring: TotalTypes,
    fall: TotalTypes
  }
}

export type TotalPerAffiliation = {
  fk_id_affiliation: number,
  fk_id_currency: number,
  term: {
    spring: TotalTypes,
    fall: TotalTypes,
  },
  year: TotalTypes,
  grades: TotalPerGrade[]
}

export type TotalPerCurrency = TotalTypes & {
  fk_id_currency: number,
}

export type TotalPerMonth = {
  month: number,
  total: TotalPerCurrency[]
}

export type Total = {
  per_month: TotalPerMonth[],
  per_year: TotalPerCurrency[],
  per_affiliation: TotalPerAffiliation[],
}

export type Month = { id: number, name: string, tag: string }

const months: Month[] = [
  { id: 1, name: 'January', tag: 'Jan' },
  { id: 2, name: 'February', tag: 'Feb' },
  { id: 3, name: 'March', tag: 'Mar' },
  { id: 4, name: 'April', tag: 'Apr' },
  { id: 5, name: 'May', tag: 'May' },
  { id: 6, name: 'June', tag: 'Jun' },
  { id: 7, name: 'July', tag: 'Jul' },
  { id: 8, name: 'August', tag: 'Aug' },
  { id: 9, name: 'September', tag: 'Sep' },
  { id: 10, name: 'October', tag: 'Oct' },
  { id: 11, name: 'November', tag: 'Nov' },
  { id: 12, name: 'December', tag: 'Dec' },
]

type ExcelRow = {
  'Affiliation': string,
  'Grade': string,
  'Total (Year)': number | null,
  'Jan': number | null,
  'Feb': number | null,
  'Mar': number | null,
  'Apr': number | null,
  'May': number | null,
  'Jun': number | null,
  'Jul': number | null,
  'Aug': number | null,
  'Sep': number | null,
  'Oct': number | null,
  'Nov': number | null,
  'Dec': number | null,
}

export default function AccountBalance() {
  const [year, setYear] = useState(2024)
  const [term, setTerm] = useState(1)
  const [displayedValues, setDisplayedValues] = useState<string>('total_due_value')
  const displayedMonths = useMemo(() => {
    if (term === 1) return months.filter(month => month.id <= 6)
    return months.filter(month => month.id >= 7)
  }, [term])
  const [expandedRows, setExpandedRows] = useState<number[]>([])

  const [affiliations, setAffiliations] = useState<Affiliation[]>([])
  const [data, setData] = useState<ViewAccountBalance[]>([])
  const [total, setTotal] = useState<Total>({
    per_month: [],
    per_year: [],
    per_affiliation: []
  })
  const [loading, setLoading] = useState(false)

  const refreshTable = useCallback(async () => {
    setLoading(true)

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/account-balance?year=${year}`,
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)
      setExpandedRows([])
      setAffiliations(data.affiliationsWithGrades)
      setData(data.allEntries)
      setTotal({
        per_month: data.totalPerMonth,
        per_year: data.totalPerYear,
        per_affiliation: data.totalPerAffiliation
      })
    } catch (err) {
      console.log(err)
    }

    setLoading(false)
  }, [year, setExpandedRows, setAffiliations, setData, setTotal, setLoading])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  const exportToExcel = () => {
    const wb = utils.book_new();

    const sheets: { valueType: keyof TotalTypes, [key: string]: string }[] = [
      { valueType: 'total_gross_value', name: 'Gross Values' },
      { valueType: 'total_due_value', name: 'Due Values' },
      { valueType: 'total_paid_value', name: 'Paid Values' },
    ]

    sheets.forEach(sheet => {
      const excelRows: ExcelRow[] = []

      for (const affiliation of affiliations) {
        for (const grade of affiliation.grades) {
          const gradeTotal = data
            .filter(cell => cell.fk_id_affiliation === affiliation.fk_id_affiliation && cell.fk_id_grade === grade.fk_id_grade)
            .reduce((acc, curr) => ({
              total_gross_value: acc.total_gross_value + curr.total_gross_value,
              total_due_value: acc.total_due_value + curr.total_due_value,
              total_paid_value: acc.total_paid_value + (curr.total_paid_value || 0),
            }), { total_gross_value: 0, total_due_value: 0, total_paid_value: 0 })
          const affiliationGradeRowMonths = data.filter(affGradeMonth => affGradeMonth.fk_id_affiliation === affiliation.fk_id_affiliation && affGradeMonth.fk_id_grade === grade.fk_id_grade)
          const monthValues = months.map(monthObj => {
            const currentMonthValue = affiliationGradeRowMonths.find(affMonth => affMonth.month === monthObj.id)
            return currentMonthValue ? currentMonthValue[sheet.valueType] : null
          })

          let newRow: ExcelRow = {
            "Affiliation": affiliation.affiliation_name,
            "Grade": grade.grade_name || '(Undefined)',
            "Total (Year)": gradeTotal[sheet.valueType] || null,
            'Jan': monthValues[0],
            'Feb': monthValues[1],
            'Mar': monthValues[2],
            'Apr': monthValues[3],
            'May': monthValues[4],
            'Jun': monthValues[5],
            'Jul': monthValues[6],
            'Aug': monthValues[7],
            'Sep': monthValues[8],
            'Oct': monthValues[9],
            'Nov': monthValues[10],
            'Dec': monthValues[11],
          }

          excelRows.push(newRow)
        }
      }

      const ws = utils.json_to_sheet(excelRows);
      utils.book_append_sheet(wb, ws, sheet.name);
    })

    writeFile(wb, `Balance Account - ${year}.xlsx`);
  }

  const handleExpandAll = () => {
    if (loading) return
    setExpandedRows(prev => {
      if (prev.length === affiliations.length) return []
      else return affiliations.map(aff => aff.fk_id_affiliation)
    })
  }

  return <>
    <Grid item xs={2} sx={{ display: 'flex' }}>
      <IconButton disabled={year === 2018 || loading} onClick={() => setYear(prev => prev - 1)} >
        <NavigateBefore />
      </IconButton>
      <Typography variant='h5' sx={{ marginInline: '1rem', display: 'flex', alignItems: 'center' }} >
        Year: {year}
      </Typography>
      <IconButton disabled={loading} onClick={() => setYear(prev => prev + 1)}>
        <NavigateNext />
      </IconButton>
    </Grid>
    <Grid item xs={2.5} sx={{ display: 'flex' }} >
      <IconButton disabled={term === 1} onClick={() => setTerm(1)}>
        <NavigateBefore />
      </IconButton>
      <Typography variant='h5' sx={{ marginInline: '1rem', display: 'flex', alignItems: 'center' }} >
        Term: {term === 1 ? 'Spring' : 'Fall'}
      </Typography>
      <IconButton disabled={term === 2} onClick={() => setTerm(2)}>
        <NavigateNext />
      </IconButton>
    </Grid>
    {/* Spacer */}
    <Grid item flexGrow={1} />
    {/* Buttons */}
    <Grid item sx={{ display: 'flex' }}>
      <span
        style={{ color: loading ? '#FFF6' : 'white', paddingRight: '0.5rem', display: 'flex', alignItems: 'center', cursor: loading ? 'default' : 'pointer' }}
        onClick={handleExpandAll}
      >
        {expandedRows.length === affiliations.length && affiliations.length !== 0 ? 'Retract all' : 'Expand all'}
      </span>
      <IconButton
        onClick={handleExpandAll}
        disabled={loading}
      >
        {expandedRows.length === affiliations.length && affiliations.length !== 0 ? <UnfoldLessDouble /> : <UnfoldMoreDouble />}
      </IconButton>
    </Grid>
    <Grid item>
      <Paper sx={{ padding: '0px 10px' }}>
        <RadioGroup
          row
          value={displayedValues}
          onChange={(e) => setDisplayedValues(e.target.value)}
        >
          <FormControlLabel value="total_gross_value" control={<Radio />} label="Gross" />
          <FormControlLabel value="total_due_value" control={<Radio />} label="Due" />
          <FormControlLabel value="total_paid_value" control={<Radio />} label="Paid" />
        </RadioGroup>
      </Paper>
    </Grid >

    <Grid item xs={1.5}>
      <Button
        id='btn-xlsx'
        disabled={!data.length}
        sx={{ backgroundColor: 'var(--color-darkGreen)', color: 'white' }}
        startIcon={<Download />}
        onClick={exportToExcel}
      >
        XLSX
      </Button>
    </Grid>

    <Grid item xs={12} maxHeight={'75vh'}>
      {
        loading ?
          <PreLoader height='100%' />
          :
          <AccountBalanceTable
            year={year}
            term={term}
            months={displayedMonths}
            affiliations={affiliations}
            displayedValues={displayedValues}
            filteredData={data.filter(row => displayedMonths.some(_month => _month.id === row.month))}
            total={total}
            expandedRows={{ state: expandedRows, setter: setExpandedRows }}
          />
      }
    </Grid>
  </>
}
