import { Checkbox, IconButton } from "@mui/material"
import { BodyContainer, BodyRow, Cell, HeaderRow, Overhead, Status, Table } from "wed-components"
import { ExpectedVouchers, Panel, SortCustomers } from "../_billings/AssignBundles"
import SortingHeader02 from "../../assets/SortingHeader02"
import { Visibility } from '@mui/icons-material'
import { ViewBundle, ViewCustomer } from "./types"

type BundlesTableProps = {
  data: ViewBundle[]
  selectedBundleId: number | null
  setSelectedBundleId: React.Dispatch<React.SetStateAction<number | null>>
  setSelectedDefaultBillingID?: React.Dispatch<React.SetStateAction<number | null>>
  setAppliedBillings?: React.Dispatch<React.SetStateAction<number[]>>
}

export function BundlesTable({ data, selectedBundleId, setSelectedBundleId, setSelectedDefaultBillingID, setAppliedBillings }: BundlesTableProps) {
  const columnWidth = [.5, 3, .6, .6, .9]

  return <Table id='table-bundles'>
    <Overhead style={{ height: 60 }}>
      <Cell flexGrow>
        <p>
          Bundles
        </p>
      </Cell>
    </Overhead>
    <HeaderRow>
      <Cell width={columnWidth[0]}>
        <p>
          ID
        </p>
      </Cell>
      <Cell flexGrow>
        <p>
          Bundle
        </p>
      </Cell>
      <Cell width={columnWidth[2]}>
        <p>
          Affil.
        </p>
      </Cell>
      <Cell width={columnWidth[3]}>
        <p>
          Ref. Year
        </p>
      </Cell>
      <Cell width={columnWidth[4]}>
        <p>
          Vouchers
        </p>
      </Cell>
    </HeaderRow>
    <BodyContainer>
      {
        data.length ?
          data.map((_row, index) => {
            return <BodyRow key={index} selected={_row.id === selectedBundleId} onClick={() => {
              setSelectedBundleId(_row.id);
              if (setAppliedBillings !== undefined) setAppliedBillings([])
              if (setSelectedDefaultBillingID !== undefined) setSelectedDefaultBillingID(null)
            }}
            >
              <Cell width={columnWidth[0]}>
                <p>
                  {_row.id}
                </p>
              </Cell>
              <Cell flexGrow>
                <p>
                  {_row.name}
                </p>
              </Cell>
              <Cell width={columnWidth[2]}>
                <p>
                  {_row.affiliation_tag}
                </p>
              </Cell>
              <Cell width={columnWidth[3]}>
                <p>
                  {_row.reference_year}
                </p>
              </Cell>
              <Cell width={columnWidth[4]}>
                <p>
                  {_row.voucher_amount} ({_row.voucher_type_tag})
                </p>
              </Cell>
            </BodyRow>
          })
          :
          <BodyRow>
            <Cell flexGrow>
              <p>
                No bundles to show.
              </p>
            </Cell>
          </BodyRow>
      }
    </BodyContainer>
  </Table>
}

type CustomersTableProps = {
  data: ViewCustomer[]
  filteredData: ViewCustomer[]
  loadingCustomers: boolean
  selectedCustomers: number[]
  setSelectedCustomers: React.Dispatch<React.SetStateAction<number[]>>
  assignedAndExpectedVouchers: ExpectedVouchers[]
  sort: SortCustomers
  setSort: React.Dispatch<React.SetStateAction<SortCustomers>>
  setPanel: React.Dispatch<React.SetStateAction<Panel>>
}

export function CustomersTable({ data, filteredData, loadingCustomers, selectedCustomers, setSelectedCustomers, assignedAndExpectedVouchers, sort, setSort, setPanel }: CustomersTableProps) {
  const columnWidth = [.5, .5, 3, .9, 1.75, .75, 1, 1, .65]

  const defineColor = (student_status_type_name: string | null) => {
    switch (student_status_type_name) {
      case 'Admitted':
        return 'green'
      case 'Canceled':
        return 'red'
      default:
        return 'blue'
    }
  }

  const handleHeaderCheckbox = (checked: boolean) => {
    setSelectedCustomers(() => {
      if (checked) {
        return filteredData.map((_) => _.id)
      }
      else {
        return []
      }
    })
  }

  const handleCheckbox = (checked: boolean, id: number) => {
    setSelectedCustomers((selected) => {
      if (checked) {
        const _selected = [...selected]

        _selected.push(id)

        return _selected
      }
      else {
        return selected.filter((_id) => _id !== id)
      }
    })
  }

  const renderFontColor = (assigned: number, expected: number) => {
    if (assigned === 0) return '#e6dfa2' // yellow
    if (assigned !== expected) return '#cf7f7f' // red
    return '#a2e6a5' // green
  }

  return <Table id='table-customers'>
    <Overhead style={{ height: 60 }}>
      <Cell width={2}>
        <p>
          Students
        </p>
      </Cell>
      <Cell flexGrow>
        <p style={{ fontSize: 13, fontWeight: 'lighter' }}>
          Total: {data.length}
        </p>
        {
          selectedCustomers.length ?
            <p style={{ fontSize: 13, fontWeight: 'lighter' }}>
              Selected: {selectedCustomers.length}
            </p>
            :
            null
        }
        {
          filteredData.length < data.length ?
            <p style={{ fontSize: 13, fontWeight: 'lighter' }}>
              Shown (filtered): {filteredData.length} ({data.length - filteredData.length})
            </p>
            :
            null
        }
      </Cell>
    </Overhead>
    <HeaderRow>
      <Cell width={columnWidth[0]}>
        <Checkbox
          id='checkbox-all'
          checked={selectedCustomers.length === filteredData.length && filteredData.length !== 0}
          onChange={(e) => handleHeaderCheckbox(e.target.checked)}
          sx={{ transform: 'translateX(-6px)' }}
        />
      </Cell>
      <Cell width={columnWidth[1]}>
        <SortingHeader02 text='ID' column={'id'} sort={sort} setSort={setSort} />
      </Cell>
      <Cell flexGrow>
        <SortingHeader02 text='Name' column={'customer_name'} sort={sort} setSort={setSort} />
      </Cell>
      <Cell width={columnWidth[3]}>
        <SortingHeader02 text='Grade' column={'customer_grade_name'} sort={sort} setSort={setSort} />
      </Cell>
      <Cell width={columnWidth[4]}>
        <SortingHeader02 text='Program' column={'customer_program_name'} sort={sort} setSort={setSort} />
      </Cell>
      <Cell width={columnWidth[5]}>
        <p>
          Credits
        </p>
      </Cell>
      <Cell width={columnWidth[6]}>
        <p>
          Assigned / Expected
        </p>
      </Cell>
      <Cell width={columnWidth[7]}>
        <SortingHeader02 text='Status' column={'student_status_type_name'} sort={sort} setSort={setSort} />
      </Cell>
      <Cell width={columnWidth[8]}>
        <p>
          Details
        </p>
      </Cell>
    </HeaderRow>
    {
      loadingCustomers ?
        null
        :
        selectedCustomers.length ?
          <>
            {
              data.filter((_) => selectedCustomers.includes(_.id)).map((row, index) => {
                const _vouchers = assignedAndExpectedVouchers.filter((_) => _.fk_id_student === row.fk_id_customer_dependence)

                return <BodyRow key={index}>
                  <Cell width={columnWidth[0]}>
                    <Checkbox
                      id={`checkbox-selected-customer-${row.id}`}
                      checked={selectedCustomers.includes(row.id)}
                      onChange={(e) => handleCheckbox(e.target.checked, row.id)}
                      sx={{ transform: 'translateX(-6px)' }}
                    />
                  </Cell>
                  <Cell width={columnWidth[1]}>
                    <p>
                      {row.id}
                    </p>
                  </Cell>
                  <Cell flexGrow>
                    <p>
                      {row.customer_name}
                    </p>
                  </Cell>
                  <Cell width={columnWidth[3]}>
                    <p>
                      {row.customer_grade_name}
                    </p>
                  </Cell>
                  <Cell width={columnWidth[4]}>
                    <p>
                      {row.customer_program_name}
                    </p>
                  </Cell>
                  <Cell width={columnWidth[5]}>
                    {_vouchers.map((_, index) => <p key={index}>{_.credits.toFixed(1)} {_.fk_id_affiliation_course_progression ? `(${_.affiliation_course_progression_tag})` : null}</p>)}
                  </Cell>
                  <Cell width={columnWidth[6]}>
                    {
                      _vouchers.length ?
                        _vouchers.map((_, index) => <p key={index} style={{ color: renderFontColor(_.assigned_voucher_quantity, _.expected_voucher_quantity) }}>{_.assigned_voucher_quantity}/{_.expected_voucher_quantity} ({_.voucher_type_tag})</p>)
                        :
                        <p>-</p>
                    }
                  </Cell>
                  <Cell width={columnWidth[7]}>
                    <Status color={defineColor(row.student_status_type_name)}>
                      {row.student_status_type_name || ''}
                    </Status>
                  </Cell>
                  <Cell width={columnWidth[8]}>
                    {
                      _vouchers.length ?
                        <IconButton onClick={() => setPanel({ show: true, fk_id_student: row.fk_id_customer_dependence })}>
                          <Visibility />
                        </IconButton>
                        :
                        <></>
                    }
                  </Cell>
                </BodyRow>
              })
            }
            <hr style={{ width: '100%', margin: 0 }} />
          </>
          :
          null
    }
    <BodyContainer>
      {
        loadingCustomers ?
          <BodyRow>
            <Cell flexGrow>
              <p>Loading customers...</p>
            </Cell>
          </BodyRow>
          :
          filteredData.length ?
            filteredData.filter((_) => !selectedCustomers.includes(_.id)).map((row, index) => {
              const _vouchers = assignedAndExpectedVouchers.filter((_) => _.fk_id_student === row.fk_id_customer_dependence)

              return <BodyRow key={index}>
                <Cell width={columnWidth[0]}>
                  <Checkbox
                    id={`checkbox-customer-${row.id}`}
                    checked={selectedCustomers.includes(row.id)}
                    onChange={(e) => handleCheckbox(e.target.checked, row.id)}
                    sx={{ transform: 'translateX(-6px)' }}
                  />
                </Cell>
                <Cell width={columnWidth[1]}>
                  <p>
                    {row.id}
                  </p>
                </Cell>
                <Cell flexGrow>
                  <p>
                    {row.customer_name}
                  </p>
                </Cell>
                <Cell width={columnWidth[3]}>
                  <p>
                    {row.customer_grade_name}
                  </p>
                </Cell>
                <Cell width={columnWidth[4]}>
                  <p>
                    {row.customer_program_name}
                  </p>
                </Cell>
                <Cell width={columnWidth[5]}>
                  {_vouchers.map((_, index) => <p key={index}>{_.credits.toFixed(1)} {_.fk_id_affiliation_course_progression ? `(${_.affiliation_course_progression_tag})` : null}</p>)}
                </Cell>
                <Cell width={columnWidth[6]}>
                  {
                    _vouchers.length ?
                      _vouchers.map((_, index) => <p key={index} style={{ color: renderFontColor(_.assigned_voucher_quantity, _.expected_voucher_quantity) }}>{_.assigned_voucher_quantity}/{_.expected_voucher_quantity} ({_.voucher_type_tag})</p>)
                      :
                      <p>-</p>
                  }
                </Cell>
                <Cell width={columnWidth[7]}>
                  <Status color={defineColor(row.student_status_type_name)}>
                    {row.student_status_type_name || ''}
                  </Status>
                </Cell>
                <Cell width={columnWidth[8]}>
                  {
                    _vouchers.length ?
                      <IconButton onClick={() => setPanel({ show: true, fk_id_student: row.fk_id_customer_dependence })}>
                        <Visibility />
                      </IconButton>
                      :
                      <></>
                  }
                </Cell>
              </BodyRow>
            })
            :
            <BodyRow>
              <Cell flexGrow>
                <p>
                  No customers to show.
                </p>
              </Cell>
            </BodyRow>
      }
    </BodyContainer>
  </Table>
}