import { Grid, IconButton, Typography } from "@mui/material"
import { EmailSchedule } from "./types"
import { ViewFaculty } from "../faculty/FacultySearch"
import { ViewStudent } from "../students/StudentsSearch"
import ScheduleIcon from '@mui/icons-material/Schedule'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { DateTime } from "luxon"
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from "wed-components"
import PreLoader from "../PreLoader"
import { useCallback, useEffect, useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import { toastError, toastSuccess } from "../assets/customToasts"

type EmailScheduleDetailsProps = {
  id: number | string
}

export default function EmailScheduleDetails({ id }: EmailScheduleDetailsProps) {
  const columnWidth = [0.5, 3.75]

  const [loading, setLoading] = useState<boolean>(true)
  const [schedule, setSchedule] = useState<EmailSchedule | null>(null)
  const [recipients, setRecipients] = useState<(ViewStudent | ViewFaculty)[]>([])

  const getScheduledEmailDetails = useCallback(async () => {
    const _toast = toast(`Fetching details...`, { toastId: 'get-schedule-details', isLoading: true })

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_NOTIFIER_URL}/schedules/${id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_NOTIFIER_TOKEN}`
        }
      })

      console.log(data)

      setSchedule(data.schedule)
      setRecipients(data.recipients)

      toastSuccess(_toast, 'Details loaded.')
      setLoading(false)
    } catch (error) {
      console.log(error)
      toastError(_toast, 'Could not load details.')
    }
  }, [id])

  useEffect(() => {
    getScheduledEmailDetails()
  }, [getScheduledEmailDetails])

  return <Grid container spacing={1} alignItems={'center'}>
    {
      loading || !schedule ?
        <PreLoader />
        :
        <>
          <Grid item xs={11}>
            <Typography variant="h6">Send date: {DateTime.fromISO(schedule.send_date || '').toFormat('dd MMM \'at\' HH:mm')}</Typography>
          </Grid>
          <Grid item xs={1}>
            {
              !schedule.status ?
                <IconButton size="large" sx={{ background: 'transparent', color: '#D4A200' }}>
                  <ScheduleIcon />
                </IconButton>
                :
                <IconButton sx={{ background: 'transparent', color: '#2F8F46' }}>
                  <CheckCircleOutlineIcon />
                </IconButton>
            }
          </Grid>
          <Grid item xs={12}>
            <Table>
              <HeaderRow>
                <Cell width={columnWidth[0]}><span>Name</span></Cell>
              </HeaderRow>
              <BodyContainer>
                {
                  recipients.length ?
                    recipients.map((_row, index) => <BodyRow key={index}>
                      <Cell width={columnWidth[1]}>
                        <p>
                          {'student_full_name' in _row ? _row.student_full_name : _row.full_name}
                        </p>
                      </Cell>
                    </BodyRow>)
                    :
                    <BodyRow>
                      <Cell><p>No recipients</p></Cell>
                    </BodyRow>
                }
              </BodyContainer>
            </Table>
          </Grid>
        </>
    }
  </Grid>
}
