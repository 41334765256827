import { DeleteForever, FileUpload } from "@mui/icons-material"
import { Button, Grid, IconButton } from "@mui/material"
import { EmailAttachment } from "./types"
import { toast } from "react-toastify"
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from "wed-components"

type Props = {
  attachments: EmailAttachment[]
  setAttachments: React.Dispatch<React.SetStateAction<EmailAttachment[]>>
}

export default function EmailAttachments({ attachments, setAttachments }: Props) {

  const handleBase64 = (base64: string) => {
    return base64.replace(/^data:\w+\/\w+;base64,/, "")
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files && files.length > 0) {
      try {
        const newAttachments = await Promise.all(

          Array.from(files).map((file) => {

            return new Promise<{ file_name: string; file_base64: string, file_type: string }>((resolve) => {
              const reader = new FileReader()

              reader.readAsDataURL(file)

              reader.onload = (event) => {
                resolve({
                  file_name: file.name,
                  file_base64: handleBase64(event.target?.result as string),
                  file_type: file.type as string
                })
              }
            })
          })
        )

        setAttachments((_attachments) => [..._attachments, ...newAttachments])
        return
      } catch (error) {
        console.log(error)
        toast.error('Could not upload files')
      }
    }

    toast.error('Could not upload files')
  }

  const onDeleteAttachment = (index: number) => {
    setAttachments((_attachments) => {
      return _attachments.filter((_, i) => index !== i)
    })
  }

  return (
    <>
      <Grid item xs={8} />
      <Grid item xs={4}>
        <Button
          id='button-file-upload'
          startIcon={<FileUpload />}
          component="label"
        >
          Upload File
          <input
            hidden
            type="file"
            multiple
            onChange={(e) => handleFileChange(e)}
          />
        </Button>
      </Grid>
      <Grid item xs={12} maxHeight={'51vh'}>
        <Table>
          <HeaderRow>
            <Cell width={7}><p>Name</p></Cell>
            <Cell><p></p></Cell>
          </HeaderRow>
          <BodyContainer>
            {
              attachments.length ?
                attachments.map((row, index) => <BodyRow key={index}>
                  <Cell width={7}><p>{row.file_name}</p></Cell>
                  <Cell>
                    <IconButton onClick={() => onDeleteAttachment(index)}>
                      <DeleteForever />
                    </IconButton>
                  </Cell>
                </BodyRow>
                )
                :
                <BodyRow>
                  <Cell width={7}><p>No attachments uploaded.</p></Cell>
                </BodyRow>
            }
          </BodyContainer>
        </Table>
      </Grid>
    </>
  )
}
