import { Container, Grid, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import Header from '../components/header/Header'
import AutomatedServicesScheduleUpdates from '../components/developers/AutomatedServices'

export default function DevelopersView() {
  const [tab, setTab] = useState('automated-services')

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const renderComponent = () => {
    switch (tab) {
      case 'automated-services':
        return <AutomatedServicesScheduleUpdates />
      default:
        return <AutomatedServicesScheduleUpdates />
    }
  }

  return (
    <>
      <Container>
        <Grid container maxWidth='xl' sx={{ marginTop: '0px' }} spacing={1}>
          <Grid item xs={12}>
            <Header title={'Developers'} name={''} />
          </Grid>
          <Grid item xs={12}>
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab id='developers-tab-automated-services' label='Automated Services' value='automated-services' />
            </Tabs>
          </Grid>
          {renderComponent()}
        </Grid>
      </Container>
    </>
  )
}
