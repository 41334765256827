import { DateTime } from "luxon"
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from "wed-components"
import { Form, AutomatedServices } from "./AutomatedServices"
import { CheckCircle, Error } from "@mui/icons-material"

type TriggersTableProps = {
  data: AutomatedServices[]
  form: Form
  setForm: React.Dispatch<React.SetStateAction<Form>>
}

export default function ServicesAndTriggersTable({ data, form, setForm }: TriggersTableProps) {

  const columns = [0.6, 1.35, 2, 2.5, 3, 4, 6]

  const getTriggerStatus = (row: AutomatedServices) => {
    switch (row.lastUpdates[0]?.fail) {
      case false:
        return <CheckCircle color='success' />
      case true:
        return <Error color='error' />
      default:
        return <p>No status</p>
    }
  }

  const formatDate = (row: AutomatedServices) => {
    if (!row.lastUpdates.length) return 'No date'

    return DateTime.fromISO(row.lastUpdates[0].last_update).toFormat('dd-LLL \'at\' HH:mm')
  }

  return <>
    <Table>
      <HeaderRow>
        <Cell width={columns[0]}>
          <p>
            ID
          </p>
        </Cell>
        <Cell width={columns[3]}>
          <p>
            Automated Service Name
          </p>
        </Cell>
        <Cell width={columns[5]}>
          <p>
            Trigger
          </p>
        </Cell>
        <Cell width={columns[4]}>
          <p>
            Path
          </p>
        </Cell>
        <Cell width={columns[1]}>
          <p>
            Last Update
          </p>
        </Cell>
      </HeaderRow>
      <BodyContainer>
        {
          data.length ?
            data.map((row, index) => <BodyRow key={index} selected={row.id === form.id} onClick={() => setForm({ show: false, id: row.id })}>
              <Cell width={columns[0]}>
                <p>{row.id}</p>
              </Cell>
              <Cell width={columns[3]}>
                <p>{row.automated_service_name}</p>
              </Cell>
              <Cell width={columns[5]}>
                <p>{row.serviceSchedule?.map((_) => _.trigger_name).join(', ')}</p>
              </Cell>
              <Cell width={columns[4]}>
                <p>{row.path}</p>
              </Cell>
              <Cell width={columns[1]}>
                <p>{formatDate(row)}</p>
              </Cell>
              <Cell width={1}>
                {getTriggerStatus(row)}
              </Cell>
              {/* <Cell width={columns[0]}>
                <Tooltip title="Run this service">
                  <IconButton onClick={() => { }}>
                    <PlayArrow />
                  </IconButton>
                </Tooltip>
              </Cell> */}
            </BodyRow>
            )
            :
            <BodyRow>
              <Cell flexGrow>
                <p>
                  No Services to show.
                </p>
              </Cell>
            </BodyRow>
        }
      </BodyContainer>
    </Table>
  </>
}