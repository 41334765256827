import { DateTime } from "luxon"
import { AutomatedServices, Form } from "./AutomatedServices"
import { Grid, Typography } from "@mui/material"
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from "wed-components"

//icons
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useContext } from "react"
import { LookupTablesContext } from "../../context/LookupTablesContext"

type AutomatedServiceAllLastUpdateProps = {
  selectedService: AutomatedServices
  form: Form
}


export default function AutomatedServiceAllLastUpdate({ selectedService, form }: AutomatedServiceAllLastUpdateProps) {
  console.log(selectedService)
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)

  const getTriggerStatus = (fail: boolean | null) => {
    switch (fail) {
      case false:
        return <CheckCircleIcon color='success' />
      case true:
        return <ErrorIcon color='error' />
      default:
        return <p>No status</p>
    }
  }

  return <Grid container spacing={1}>
    {
      form.panel === 'edit' ?
        <Grid item xs={12}>
          <Typography variant='h6'>Last Updates of {selectedService?.automated_service_name}</Typography>
        </Grid>
        :
        <></>
    }
    <Grid item xs={12}>
      {
        selectedService.serviceSchedule.map((trigger, index) => <Typography key={index} variant='body1'>
          <b>{trigger.trigger_name}:</b> {trigger.description}
        </Typography>
        )
      }
    </Grid>
    <Grid item xs={12} maxHeight={'80vh'}>
      <Table>
        <HeaderRow>
          <Cell width={1.5}>
            <p>
              Date
            </p>
          </Cell>
          <Cell width={3}>
            <p>
              Trigger
            </p>
          </Cell>
          <Cell width={1}>
            <p>
              Status
            </p>
          </Cell>
        </HeaderRow>
        <BodyContainer>
          {
            selectedService.lastUpdates.length ?
              selectedService.lastUpdates.map((row, index) => <BodyRow key={index}>
                <Cell width={1.5}>
                  <p>
                    {row.last_update ? DateTime.fromISO(row.last_update).toFormat('dd-LLL \'at\' HH:mm') : <p>No Date</p>}
                  </p>
                </Cell>
                <Cell width={3}>
                  <p>
                    {lookupTables.triggers.find((_) => _.id === row.fk_id_trigger)?.option}
                  </p>
                </Cell>
                <Cell width={1}>
                  <p>
                    {getTriggerStatus(row.fail)}
                  </p>
                </Cell>
              </BodyRow>
              )
              :
              <BodyRow>
                <Cell flexGrow>
                  <p>
                    No registers for last update.
                  </p>
                </Cell>
              </BodyRow>
          }
        </BodyContainer>
      </Table>
    </Grid>
    <Grid item xs={12}></Grid>
  </Grid>
}