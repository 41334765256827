import axios from "axios"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { toastError, toastSuccess } from "../assets/customToasts"
import { ViewStudent } from "../students/StudentsSearch"
import { ViewFaculty } from "../faculty/FacultySearch"
import { GroupedRule, TemplateWithRules } from "./types"
import { Box, Grid, Paper, Typography } from "@mui/material"
import PreLoader from "../PreLoader"
import { DateTime } from "luxon"
import { LookupTablesContext } from "../../context/LookupTablesContext"
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from "wed-components"

type Props = {
    id: number | string
    scheduleRule: TemplateWithRules[]
}

export default function EmailScheduleRulesDetails({ id, scheduleRule }: Props) {
    const { lookupTables } = useContext(LookupTablesContext)

    const columnWidth = [0.5, 3.75]

    const [loading, setLoading] = useState<boolean>(true)
    const [recipients, setRecipients] = useState<(ViewStudent | ViewFaculty)[]>([])

    const selectedEmail = useMemo(() => {
        return scheduleRule.find((_) => _.id === id)
    }, [scheduleRule])



    const groupedRules = useMemo(() => {
        if (!selectedEmail) return

        let rules = selectedEmail?.rules

        const groupedIds = [...new Set(rules?.map((item) => item.grouper_id))]

        return groupedIds.map((id) => {
            return {
                rules: rules.filter((item) => item.grouper_id === id),
                grouper_id: id
            }
        })

    }, [selectedEmail])

    console.log('', selectedEmail, groupedRules)


    const returnRuleDescription = (item: GroupedRule) => {
        //Daily
        if (item.rules[0].fk_id_email_rules_frequency === 1) {
            if (item.rules.length === 5) return 'From Monday to Friday'
            return 'Every day of week'
        }

        //Weekly
        if (item.rules[0].fk_id_email_rules_frequency === 2) {

            let selectedDaysOfWeek = item.rules.map((_) => _.fk_id_weekdays)

            let _selectedDaysOfWeek = lookupTables.weekDays
                .filter((_) => selectedDaysOfWeek.includes(_.id))
                .sort((a, b) => a.id - b.id)
                .map((day) => day.option)

            if (_selectedDaysOfWeek.length > 1) {
                const lastDay = _selectedDaysOfWeek.pop()
                return `Every ${_selectedDaysOfWeek.join(', ')} and ${lastDay}`
            } else {
                return `Every ${_selectedDaysOfWeek[0]}`
            }
        }

        //Monthly
        if (item.rules[0].fk_id_email_rules_frequency === 3) {

            let days = item.rules.map((_) => _.day).filter((value, index, arr) => arr.indexOf(value) === index)

            let monthsIds = item.rules.map((_) => _.month)

            let months = lookupTables.months.filter((_) => monthsIds.includes(_.id))

            let lastDay = days.pop()
            let _days = days.length > 1 ? `${days.map((day) => `${day}`).join(', ')} and ${lastDay}` : `${lastDay}`

            let lastMonth = months.pop()
            let _months = months.length > 1 ? `${months.map((month) => `${month.option}`).join(', ')} and ${lastMonth?.option}` : `${lastMonth?.option}`

            return `Every ${days.length > 1 ? 'days' : 'day'}  ${_days}, of ${_months}`
        }
    }

    const getScheduledEmailDetails = useCallback(async () => {
        const _toast = toast(`Fetching details...`, { toastId: 'get-schedule-details', isLoading: true })

        try {
            const { data } = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_NOTIFIER_URL}/schedules-rules/${id}`,
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_NOTIFIER_TOKEN}`
                }
            })

            console.log(data)

            setRecipients(data.recipients)

            toastSuccess(_toast, 'Details loaded.')
            setLoading(false)
        } catch (error) {
            console.log(error)
            toastError(_toast, 'Could not load details.')
        }
    }, [id])

    useEffect(() => {
        getScheduledEmailDetails()
    }, [getScheduledEmailDetails])

    return <Grid container spacing={1} alignItems={'center'}>
        {
            loading ?
                <PreLoader />
                :
                <>
                    <Grid item xs={12}>
                        {
                            groupedRules?.length ?
                                <Box sx={{
                                    maxHeight: 300,
                                    overflowY: 'auto',
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        background: '#132F39',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: '#f2f2f2',
                                        borderRadius: '10px',
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                        background: '#0d1e27',
                                    },
                                    paddingRight: 1
                                }}>
                                    {
                                        groupedRules.map((item, index) => (
                                            <Paper key={index} sx={{ background: '#f2f2f2', color: '#000', paddingY: 1, paddingX: 2, marginBottom: 1 }}>
                                                <Grid container spacing={0.1}>
                                                    <Grid item xs={12}>
                                                        <Typography sx={{ color: '#132F39', fontWeight: 600 }}>{returnRuleDescription(item)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography sx={{ color: '#132F39', fontWeight: 600 }}>At {item.rules[0].time ? DateTime.fromISO(item.rules[0].time).toFormat('HH:mm:ss') : ''}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography sx={{ color: '#132F39', fontWeight: 600 }}>Untill {item.rules[0].end_date ? DateTime.fromISO(item.rules[0].end_date).toFormat('dd-LLL-yy') : ''}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        ))
                                    }
                                </Box>
                                :
                                <></>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <HeaderRow>
                                <Cell width={columnWidth[0]}><span>Name</span></Cell>
                            </HeaderRow>
                            <BodyContainer>
                                {
                                    recipients.length ?
                                        recipients.map((_row, index) => <BodyRow key={index}>
                                            <Cell width={columnWidth[1]}>
                                                <p>
                                                    {'student_full_name' in _row ? _row.student_full_name : _row.full_name}
                                                </p>
                                            </Cell>
                                        </BodyRow>)
                                        :
                                        <BodyRow>
                                            <Cell><p>No recipients</p></Cell>
                                        </BodyRow>
                                }
                            </BodyContainer>
                        </Table>
                    </Grid>
                </>
        }
    </Grid>
}
