import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Tooltip } from "@mui/material"
import { DateTime } from "luxon"
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from "wed-components"
import ScheduleIcon from '@mui/icons-material/Schedule'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ClearIcon from '@mui/icons-material/Clear'
import { EmailSchedule, Panel } from "./types"
import { Dispatch, SetStateAction } from "react"
import { MoreVert, Visibility } from "@mui/icons-material"

type EmailScheduleTableProps = {
  setOpenModal: Dispatch<SetStateAction<boolean>>
  emailSchedule: EmailSchedule[]
  setPanel: Dispatch<SetStateAction<Panel>>
  selectedRow: { button?: HTMLElement, id: number } | null,
  setSelectedRow: Dispatch<SetStateAction<{ button?: HTMLElement, id: number } | null>>
}

export default function EmailScheduleTable({ setPanel, setOpenModal, emailSchedule, selectedRow, setSelectedRow }: EmailScheduleTableProps) {
  const columnWidth = [0.6, 1.5]

  return <Table>
    <HeaderRow>
      <Cell width={columnWidth[0]}><p>ID</p></Cell>
      <Cell flexGrow><p>Template name</p></Cell>
      <Cell width={columnWidth[1]}><p>Send date</p></Cell>
      <Cell width={1.2}><p></p></Cell>
    </HeaderRow>
    <BodyContainer>
      {
        emailSchedule.length ?
          emailSchedule.map((_row, index) => (
            <BodyRow key={_row.id}>
              <Cell width={columnWidth[0]}>
                <p>{_row.id}</p>
              </Cell>
              <Cell flexGrow>
                <p>{_row.email_template?.subject}</p>
              </Cell>
              <Cell width={columnWidth[1]}>
                <p>{DateTime.fromISO(_row.send_date).toFormat('dd MMM yyyy \'at\' HH:mm')}</p>
              </Cell>
              <Cell width={.6}>
                {
                  !_row.status ?
                    <Tooltip title='Not sent yet.'>
                      <IconButton sx={{ background: 'transparent' }}>
                        <ScheduleIcon />
                      </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title='Succesfully sent'>
                      <IconButton sx={{ background: 'transparent' }}>
                        <CheckCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                }
              </Cell>
              <Cell width={columnWidth[0]}>
                <IconButton id={`button-menu-single-schedule-${_row.id}`} onClick={(e) => setSelectedRow({ button: e.currentTarget, id: Number(_row.id) })}>
                  <MoreVert />
                </IconButton>
              </Cell>
              <Menu
                id={`menu-schedule-${_row.id}`}
                open={Boolean(selectedRow?.button)}
                onClose={() => setSelectedRow(null)}
                anchorEl={selectedRow?.button}
              >
                <MenuList>
                  <MenuItem
                    id='menu-option-visualize'
                    onClick={() => {
                      setPanel({ id: selectedRow?.id, show: true, panel: 'schedules' })
                      setSelectedRow(prev => ({ id: prev?.id || 0 }))
                    }}
                  >
                    <ListItemIcon>
                      <Visibility />
                    </ListItemIcon>
                    <ListItemText>
                      Visualize
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    id='menu-option-delete'
                    onClick={() => {
                      setOpenModal(true)
                      setSelectedRow(prev => ({ id: prev?.id || 0 }))
                    }}
                  >
                    <ListItemIcon >
                      <ClearIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Delete
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </Menu>
            </BodyRow>
          ))
          :
          <BodyRow><Cell width={columnWidth[1]}><p>No schedulings</p></Cell></BodyRow>
      }
    </BodyContainer >
  </Table >
}







