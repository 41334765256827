export default function weekdays() {
    return [
        { id: 1, label: 'Monday', short: 'Mon' },
        { id: 2, label: 'Tuesday', short: 'Tue' },
        { id: 3, label: 'Wednesday', short: 'Wed' },
        { id: 4, label: 'Thursday', short: 'Thu' },
        { id: 5, label: 'Friday', short: 'Fri' },
        { id: 6, label: 'Saturday', short: 'Sat' },
        { id: 7, label: 'Sunday', short: 'Sun' }
    ]
}

export function generateRandomID() {
    const id = crypto.getRandomValues(new Uint32Array(1))[0].toString(16)
    return id.slice(0, 12)
}