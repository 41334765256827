import axios from "axios"
import { useMsal } from "@azure/msal-react"
import { toast } from "react-toastify"
import { toastError, toastSuccess } from "../assets/customToasts"
import { useCallback, useEffect, useMemo, useState } from "react"
import { variables } from "./utils"
import { Button, Checkbox, FormControlLabel, Grid, MenuItem, Paper, Tab, Tabs, TextField } from "@mui/material"
import { ViewFaculty } from "../faculty/FacultySearch"
import { ViewStudent } from "../students/StudentsSearch"
import { Email, EmailAttachment, EmailScheduleRule, Filters, Recipient } from "./types"
import { Warning, MarkEmailRead } from '@mui/icons-material'
import VariablesTable from "./VariablesTable"
import Schedule from "./Schedule"
import EmailRecipientsTable from "./EmailRecipientsTable"
import EmailFilters from "./EmailFilters"
import { RightSidePanel } from "wed-components"
import EmailAttachments from "./EmailAttachments"
import ScheduleRules from "./ScheduleRules"

export type EmailTabs = 'filters' | 'variables' | 'schedule' | 'attachments'

export type ExtraVariablesMap = { name: string, placeholder: string }

export default function SendEmail() {
  // Hooks
  const { accounts } = useMsal()
  const username = useMemo(() => accounts[0]?.username || 'Cypress testing', [accounts])
  // General
  const [tested, setTested] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [panel, setPanel] = useState(false)
  const [tab, setTab] = useState<EmailTabs>('filters')
  // Data
  const [email, setEmail] = useState<Email>({
    sender: username || '',
    subject: '',
    message: ''
  })
  const [recipients, setRecipients] = useState<ViewFaculty[] | ViewStudent[]>([])
  const [scheduleList, setScheduleList] = useState<string[]>([])
  const [recipientsType, setRecipientsType] = useState<Recipient>('students')
  const [recipientIds, setRecipientIds] = useState<number[]>([])
  const [extraVariables, setExtraVariables] = useState<object[]>([])
  const [attachments, setAttachments] = useState<EmailAttachment[]>([])
  const [scheduleRules, setScheduleRules] = useState<EmailScheduleRule[]>([])
  const [isRecurrentEmail, setIsRecurrentEmail] = useState(false)

  // Filters
  const [filters, setFilters] = useState<Filters>({
    fk_id_affiliation: [],
    fk_id_faculty_type: [],
    fk_id_student_status_type: [],
    fk_id_grade: [],
    fk_id_program: [],
    admissionYear: []
  })

  const renderComponent = () => {
    switch (tab) {
      case 'filters':
        return <EmailFilters
          filters={filters}
          recipientsType={recipientsType}
          setFilters={setFilters}
          setRecipients={setRecipients}
          setRecipientIds={setRecipientIds}
          setRecipientsType={setRecipientsType}
          tab={tab}
        />
      case 'variables':
        return <VariablesTable
          receivers={recipientIds}
          extraVariablesMap={extraVariablesMap}
          extraVariables={extraVariables}
          variables={availableVariables}
          setExtraVariables={setExtraVariables}
          recipientsType={recipientsType}
          data={recipients}
        />
      case 'schedule':
        return <>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Is recurring E-mail?'}
              control={
                <Checkbox
                  id='form-field-is-recurring-email'
                  checked={isRecurrentEmail}
                  onChange={(e) => setIsRecurrentEmail(e.target.checked)}
                />}
            />
          </Grid>
          {
            isRecurrentEmail ?
              <ScheduleRules isRecurrentEmail={isRecurrentEmail} scheduleRules={scheduleRules} setScheduleRules={setScheduleRules} />
              :
              <Schedule schedule={scheduleList} setSchedule={setScheduleList} />
          }
        </>
      case 'attachments':
        return <EmailAttachments
          attachments={attachments}
          setAttachments={setAttachments}
        />
      default:
        return <></>
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: EmailTabs) => {
    setTab(newValue)
  }

  const sendRules = useMemo(() => {
    if (!email.message || !email.subject || !email.sender) {
      return {
        message: 'E-mail form is empty. Insert sender, subject and message.',
        send: true,
        test: true,
        color: '#D4A200',
        icon: <Warning />
      }
    }
    if (!recipientIds.length) {
      return {
        message: 'Recipients list is empty. Combine filters and manage the list.',
        send: true,
        test: false,
        color: '#D4A200',
        icon: <Warning />
      }
    }
    if (!tested) {
      return {
        message: 'Before sending the e-mail, you need to test to yourself.',
        send: true,
        test: false,
        color: '#D4A200',
        icon: <Warning />
      }
    }
    return {
      message: scheduleList.length ? 'Ready to schedule you e-mail.' : 'Ready to send your e-mail.',
      send: false,
      test: false,
      color: '#2F8F46',
      icon: <MarkEmailRead />
    }
  }, [email, recipientIds, tested, scheduleList])

  const availableVariables = useMemo(() => {
    return variables.filter((_var) => _var.type === recipientsType)
  }, [recipientsType])

  const extraVariablesMap = useMemo<ExtraVariablesMap[]>(() => {
    if (!extraVariables.length) return []

    const keys = Object.keys(extraVariables[0])
      .filter((item) => !['id', 'student_full_name', 'faculty_full_name'].includes(item))

    return keys.map((key) => ({ name: key, placeholder: '%' + key }))
  }, [extraVariables])

  useEffect(() => {
    setFilters({
      fk_id_affiliation: [],
      fk_id_faculty_type: [],
      fk_id_student_status_type: [],
      fk_id_grade: [],
      fk_id_program: [],
      admissionYear: []
    })
    setRecipientIds([])
  }, [recipientsType])

  useEffect(() => {
    setTested(false)
  }, [email])

  const sendEmail = useCallback(async (test: boolean) => {

    setDisabledButton(true)

    const _toast = toast(`${test ? `Sending test e-mail to ${username}` : 'Sending e-mail'}`, { toastId: 'toast-send-email', isLoading: true })

    try {
      const { data } = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_NOTIFIER_URL}/send-email-now`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_NOTIFIER_TOKEN}`
        },
        data: {
          email,
          username: username,
          recipientsType,
          recipientIds,
          extraVariables,
          test,
          attachments
        }
      })

      console.log(data)

      if (test) setTested(true)

      toastSuccess(_toast, 'E-mail sent.')
      setDisabledButton(false)
    } catch (error) {
      console.log(error)
      toastError(_toast, 'Could not send e-mail.')
      setDisabledButton(false)
    }

  }, [email, extraVariables, recipientIds, recipientsType, username, attachments])

  const scheduleEmails = useCallback(async () => {
    setDisabledButton(true)

    const _toast = toast(`Scheduling e-mail`, { toastId: 'toast-scheduling-email', isLoading: true })

    try {
      await axios({
        method: 'POST',
        url: scheduleRules.length ? `${process.env.REACT_APP_NOTIFIER_URL}/schedules-rules` : `${process.env.REACT_APP_NOTIFIER_URL}/schedules`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_NOTIFIER_TOKEN}`
        },
        data: {
          email,
          username: username,
          email_schedules: scheduleList.length > 0 ? scheduleList : scheduleRules,
          recipientsType,
          recipientIds,
          extraVariables: JSON.stringify(extraVariables),
          attachments
        }
      })

      toastSuccess(_toast, 'Scheduled e-mail saved.')

      setDisabledButton(false)
    } catch (error) {
      console.log(error)
      setDisabledButton(false)
      toastError(_toast, 'Could not save scheduled e-mail.')
    }
  }, [email, extraVariables, scheduleList, recipientIds, recipientsType, username, attachments, scheduleRules])

  const senderOptions = useMemo(() => {
    const data = [{
      value: 'marketing@wk12.org',
      label: 'Marketing <marketing@wk12.org>',
      roles: ['dev', 'secretary', 'finances', 'admin', 'academic']
    },
    {
      value: 'portal@wk12.org',
      label: 'Portal <portal@wk12.org>',
      roles: ['dev', 'secretary', 'admin', 'academic']
    },
    {
      value: 'registrar@wk12.org',
      label: 'Registrar <registrar@wk12.org>',
      roles: ['dev', 'secretary', 'admin']
    },
    {
      value: 'academic@wk12.org',
      label: 'Academic <academic@wk12.org>',
      roles: ['dev', 'academic', 'admin']
    }]

    const result = data.filter((_) => accounts.length ? accounts[0].idTokenClaims?.roles?.some(role => _.roles.includes(role)) : true)

    return result

  }, [accounts])

  useEffect(() => {
    setScheduleList([])
  }, [isRecurrentEmail])

  return <>
    {
      panel ?
        <RightSidePanel state={panel} close={() => setPanel(false)} title='Manage List'>
          <Grid container>
            <EmailRecipientsTable
              recipients={recipientIds}
              setRecipients={setRecipientIds}
              data={recipients}
            />
          </Grid>
        </RightSidePanel>
        :
        null
    }
    <Grid item xs={6.5}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            label='Sender'
            id={'email-input-sender'}
            value={email.sender}
            select
            onChange={(e) => setEmail({ ...email, sender: e.target.value })}
          >
            <MenuItem value={username}>You {`<${username}>`}</MenuItem>
            {
              senderOptions.map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
            }
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            id={'email-input-subject'}
            label={'Subject'}
            variant="outlined"
            value={email.subject}
            onChange={(e) => setEmail({ ...email, subject: e.target.value })}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id={'email-input-message'}
            label={'Message'}
            multiline
            rows={16}
            variant="outlined"
            value={email.message}
            onChange={(e) => setEmail({ ...email, message: e.target.value })}
          />
        </Grid>

        <Grid item xs={5}>
          <Button
            id='button-manage-list'
            onClick={() => setPanel(true)}
            disabled={disabledButton}
            sx={{ height: '50px' }}
          >
            MANAGE RECIPIENTS LIST
          </Button>
        </Grid>

        <Grid item xs={3.5}>
          <Button
            id='button-test-email'
            onClick={() => sendEmail(true)}
            disabled={sendRules.test || disabledButton}
          >
            TEST EMAIL
          </Button>
        </Grid>

        <Grid item xs={3.5}>
          {
            scheduleList.length || scheduleRules.length ?
              <Button
                id='button-save-send'
                onClick={() => scheduleEmails()}
                disabled={sendRules.send || disabledButton}
              >
                SAVE
              </Button>
              :
              <Button
                id='button-save-send'
                onClick={() => sendEmail(false)}
                disabled={sendRules.send || disabledButton}
              >
                SEND
              </Button>
          }
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={5.5}>
      <Grid container spacing={1} direction={'row'} justifyContent={'space-between'} height={'100%'}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Paper sx={{
                background: `${sendRules.color}`,
                fontSize: '17px',
                fontWeight: 500,
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 18px',
                alignItems: 'center'
              }}>
                <p>{sendRules.message}</p>
                {sendRules.icon}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Tabs value={tab} onChange={handleTabChange}>
                <Tab id='email-settings-tab-filters' label={'RECIPIENTS'} value='filters' />
                <Tab id='email-settings-tab-variables' label={'VARIABLES'} value='variables' />
                <Tab id='email-settings-tab-schedule' label={'SCHEDULE'} value='schedule' />
                <Tab id='email-settings-tab-attachments' label={'ATTACHMENTS'} value='attachments' />
              </Tabs>
            </Grid>
            {renderComponent()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
}