import axios from "axios"
import { createContext, useCallback, useEffect, useState } from "react"

export type LookupTablesOption = {
  id: number,
  option: string
}[]

export type LookupTablesType = {
  loading: boolean,
  affiliation: {
    id: number,
    option: string,
    is_active: boolean,
    tag: string
  }[],
  affiliation_type: {
    id: number,
    option: string,
    tag: string
  }[],
  baseCourses: {
    id: number,
    option: string,
    base_course_credit_default: number,
    default_weeks: number,
    fk_id_school_stage_default: number,
    fk_id_subject: number
  }[],
  baseLessons: {
    id: number,
    fk_id_course: number,
    lesson_number: number,
    content: string,
    updated_by: string,
    fk_id_credit_type: number
  }[],
  bundle: {
    id: number,
    option: string,
    voucher_duration_days: number,
    voucher_amount: number,
    description: string,
    fk_id_affiliation: number,
    reference_year: number
  }[],
  ccss: {
    id: number,
    area: string,
    standard_type: string,
    grade: string,
    strand_code: string,
    standard_number: string,
    strand_name: string,
    standard_code: string,
    standard_short_code: string,
    anchor: string,
    grade_specific: string
  }[],
  classes: {
    id: number,
    option: string,
    fk_id_section: number,
  }[],
  country: {
    id: number,
    option: string,
    fk_id_country: number
  }[],
  courseType: {
    id: number,
    option: string,
    tag: string
  }[],
  courses: {
    id: number,
    option: string,
    fk_id_base_course: number,
    fk_id_course_type: number
  }[],
  creditStatusType: LookupTablesOption,
  creditType: {
    id: number,
    option: string,
    tag: string
  }[],
  currency: {
    id: number,
    option: string,
    currency_code: string,
    is_usd_ccy: boolean,
    sign: string,
  }[],
  currentTerm: {
    id: number,
    option: string,
    term_name: string,
    start_date: string,
    end_date: string,
    weeks: number,
    academic_start_date: string,
    academic_end_date: string
  },
  customerType: LookupTablesOption,
  defaultBilling: {
    id: number,
    option: string,
    default_value: number,
    fk_id_currency: number,
    max_installments: number,
    fk_id_affiliation: number,
    affiliation_name: string,
    fk_id_program: number,
    program_tag: string,
    fk_id_service: number,
    fk_id_payment_method: number,
    fk_id_payee: number,
    fk_id_customer_type: number,
    commercial_conditions: string | null,
  }[],
  defaultPayment: {
    id: number,
    option: string,
    fk_id_payment_type: number,
    fk_id_currency: number,
    fk_id_service_provided_type: number,
    fk_id_payer_customer: number,
    tag: string,
    currency_per_multiplier: number,
    description: string,
  }[],
  elementaryExamType: LookupTablesOption,
  emailRulesFrequency: LookupTablesOption,
  faculty: {
    id: number,
    option: string,
    permissions: {
      fk_id_affiliation: number,
      fk_id_faculty: number,
      fk_id_faculty_type: number,
      id: number
    }[]
  }[],
  facultyType: LookupTablesOption,
  gender: LookupTablesOption,
  gpaGradeScaleType: LookupTablesOption,
  grade: LookupTablesOption,
  lms: LookupTablesOption,
  months: LookupTablesOption,
  company: LookupTablesOption,
  paymentMethod: {
    id: number,
    option: string,
    tag: string
  }[],
  paymentType: {
    id: number,
    option: string,
    tag: string,
    description: string
  }[],
  program: {
    id: number,
    option: string,
    tag: string,
    fk_id_school_stage: number | null
  }[],
  relationship: LookupTablesOption,
  requestStatus: LookupTablesOption,
  requestType: LookupTablesOption,
  rollCallStatus: {
    id: number,
    roll_call_status_name: string,
    roll_call_status_tag: string
  }[]
  schoolStage: {
    id: number,
    option: string,
    tag: string,
  }[],
  section: {
    id: number,
    option: string,
    fk_id_affiliation: number,
    fk_id_base_course: number,
    fk_id_course: number,
    fk_id_course_type: number,
    fk_id_credit_type: number,
    fk_id_term: number,
    start_date: string,
    end_date: string,
    weeks: number,
    credits: number,
    fk_id_school_stage: number,
    school_stage_name: string,
  }[],
  service: {
    id: number,
    option: string,
    fk_id_service_type: number
  }[],
  serviceType: LookupTablesOption,
  state: {
    id: number,
    option: string,
    fk_id_country: number
  }[],
  studentStatusType: LookupTablesOption,
  subject: LookupTablesOption,
  templateType: LookupTablesOption,
  term: {
    id: number,
    option: string,
    term_start_date: string,
    term_end_date: string,
    year: number
  }[],
  triggers: {
    id: number,
    option: string,
    description: string
  }[],
  voucherType: {
    id: number,
    option: string,
    tag: string,
    is_active: boolean,
    default_duration_days: number
  }[],
  weekDays: LookupTablesOption
}

const initialState: LookupTablesType = {
  loading: true,
  affiliation: [{
    id: 0,
    option: 'Loading...',
    is_active: true,
    tag: ''
  }],
  affiliation_type: [{
    id: 0,
    option: 'Loading...',
    tag: 'Loading...'
  }],
  baseCourses: [{
    id: 0,
    option: 'Loading...',
    base_course_credit_default: 0,
    default_weeks: 0,
    fk_id_school_stage_default: 0,
    fk_id_subject: 0
  }],
  baseLessons: [{
    id: 0,
    fk_id_course: 0,
    lesson_number: 0,
    content: '',
    updated_by: '',
    fk_id_credit_type: 0
  }],
  bundle: [{
    id: 0,
    option: 'Loading...',
    voucher_duration_days: 0,
    voucher_amount: 0,
    description: '',
    reference_year: 0,
    fk_id_affiliation: 0
  }],
  ccss: [{
    id: 0,
    area: '',
    standard_type: '',
    grade: '',
    strand_code: '',
    standard_number: '',
    strand_name: '',
    standard_code: '',
    standard_short_code: '',
    anchor: '',
    grade_specific: ''
  }],
  classes: [{
    id: 0,
    option: 'Loading...',
    fk_id_section: 0
  }],
  country: [{
    id: 0,
    option: 'Loading...',
    fk_id_country: 0
  }],
  courseType: [{
    id: 0,
    option: 'Loading...',
    tag: ''
  }],
  courses: [{
    id: 0,
    option: 'Loading',
    fk_id_base_course: 0,
    fk_id_course_type: 0
  }],
  creditStatusType: [{
    id: 0,
    option: 'Loading...'
  }],
  creditType: [{
    id: 0,
    option: 'Loading...',
    tag: ''
  }],
  currency: [{
    id: 0,
    option: 'Loading...',
    currency_code: '',
    is_usd_ccy: false,
    sign: '',
  }],
  currentTerm: {
    id: 0,
    option: 'Loading...',
    term_name: '',
    start_date: '',
    end_date: '',
    weeks: 0,
    academic_start_date: '',
    academic_end_date: ''
  },
  customerType: [{
    id: 0,
    option: 'Loading...'
  }],
  defaultBilling: [{
    id: 0,
    option: 'Loading...',
    default_value: 0,
    fk_id_currency: 0,
    max_installments: 0,
    fk_id_affiliation: 0,
    affiliation_name: 'Loading...',
    fk_id_program: 0,
    program_tag: 'Loading...',
    fk_id_service: 0,
    fk_id_payment_method: 0,
    fk_id_payee: 0,
    fk_id_customer_type: 0,
    commercial_conditions: null,
  }],
  defaultPayment: [{
    id: 0,
    option: 'Loading...',
    fk_id_payment_type: 0,
    fk_id_currency: 0,
    fk_id_service_provided_type: 0,
    fk_id_payer_customer: 0,
    tag: '',
    currency_per_multiplier: 0,
    description: '',
  }],
  elementaryExamType: [{
    id: 0,
    option: 'Loading...'
  }],
  emailRulesFrequency: [{
    id: 0,
    option: 'Loading...'
  }],
  faculty: [{
    id: 0,
    option: 'Loading...',
    permissions: []
  }],
  facultyType: [{
    id: 0,
    option: 'Loading...'
  }],
  gender: [{
    id: 0,
    option: 'Loading...'
  }],
  gpaGradeScaleType: [{
    id: 0,
    option: 'Loading...'
  }],
  grade: [{
    id: 0,
    option: 'Loading...'
  }],
  lms: [{
    id: 0,
    option: 'Loading...'
  }],
  months: [{
    id: 0,
    option: 'Loading...'
  }],
  company: [{
    id: 0,
    option: 'Loading...',
  }],
  paymentMethod: [{
    id: 0,
    option: 'Loading...',
    tag: ''
  }],
  paymentType: [{
    id: 0,
    option: 'Loading...',
    tag: '',
    description: ''
  }],
  program: [{
    id: 0,
    option: 'Loading...',
    tag: '',
    fk_id_school_stage: 0
  }],
  relationship: [{
    id: 0,
    option: 'Loading...'
  }],
  requestStatus: [{
    id: 0,
    option: 'Loading...'
  }],
  requestType: [{
    id: 0,
    option: 'Loading...'
  }],
  rollCallStatus: [{
    id: 0,
    roll_call_status_name: '',
    roll_call_status_tag: ''
  }],
  schoolStage: [{
    id: 0,
    option: 'Loading...',
    tag: ''
  }],
  section: [{
    id: 0,
    option: 'Loading...',
    fk_id_affiliation: 0,
    fk_id_base_course: 0,
    fk_id_course: 0,
    fk_id_course_type: 0,
    fk_id_credit_type: 0,
    fk_id_term: 0,
    start_date: '',
    end_date: '',
    weeks: 0,
    credits: 0,
    fk_id_school_stage: 0,
    school_stage_name: '',
  }],
  service: [{
    id: 0,
    option: 'Loading...',
    fk_id_service_type: 0
  }],
  serviceType: [{
    id: 0,
    option: 'Loading...'
  }],
  state: [{
    id: 0,
    option: 'Loading...',
    fk_id_country: 0
  }],
  studentStatusType: [{
    id: 0,
    option: 'Loading...'
  }],
  subject: [{
    id: 0,
    option: 'Loading...'
  }],
  templateType: [{
    id: 0,
    option: 'Loading...'
  }],
  term: [{
    id: 0,
    option: 'Loading...',
    term_start_date: '',
    term_end_date: '',
    year: 0
  }],
  triggers: [{
    id: 0,
    option: 'Loading...',
    description: ''
  }],
  voucherType: [{
    id: 0,
    option: 'Loading...',
    tag: '',
    is_active: true,
    default_duration_days: 0
  }],
  weekDays: [{
    id: 0,
    option: 'Loading...'
  }],
}

interface ILookupTablesContext {
  lookupTables: LookupTablesType,
  refreshLookupTables: (tables: (keyof LookupTablesType | 'all')[]) => Promise<void>
}

const LookupTablesContext = createContext<ILookupTablesContext>({
  lookupTables: initialState,
  refreshLookupTables: async () => { }
})

const LookupTablesContextProvider: React.FC<any> = ({ children }) => {
  const [lookupTables, setLookupTables] = useState<LookupTablesType>(initialState)

  const refreshLookupTables = useCallback(async (tables: string[]) => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/lookup-tables?${tables.length ? tables.map((item) => `tables[]=${item}`).join('&') : 'tables[]='}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log('[ Lookup Tables ] ', data)

      setLookupTables({ ...data, loading: false })
    } catch (err) {
      console.warn(err, 'Retrying in 30 seconds...')
      setTimeout(() => refreshLookupTables([]), 30000)
    }
  }, [setLookupTables])

  useEffect(() => {
    refreshLookupTables([])
  }, [refreshLookupTables])

  return (
    <LookupTablesContext.Provider value={{ lookupTables, refreshLookupTables }}>
      {children}
    </LookupTablesContext.Provider>
  )
}

export { LookupTablesContextProvider, LookupTablesContext }