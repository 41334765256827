import { Autocomplete, Box, Button, Checkbox, Chip, FormControlLabel, Grid, IconButton, MenuItem, Paper, TextField, Typography } from "@mui/material"
import { EmailScheduleRule, GroupedRule, Rule } from "./types"
import { useContext, useEffect, useMemo, useState } from "react"
import { LookupTablesContext } from "../../context/LookupTablesContext"
import { DatePicker, LocalizationProvider, renderTimeViewClock, TimePicker } from "@mui/x-date-pickers"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { AutocompleteOption } from "../../utilities/generalPurposeTypes"
import { DateTime } from "luxon"
import { generateRandomID } from "../../utilities/lookupWeekdays"
import { Delete } from "@mui/icons-material"

type Props = {
  scheduleRules: EmailScheduleRule[]
  setScheduleRules: React.Dispatch<React.SetStateAction<EmailScheduleRule[]>>
  isRecurrentEmail: boolean
}

export default function ScheduleRules({ isRecurrentEmail, scheduleRules, setScheduleRules }: Props) {

  const { lookupTables } = useContext(LookupTablesContext)

  const [days, setDays] = useState<number[]>([])
  const [weekdays, setWeekdays] = useState<AutocompleteOption[]>([])
  const [months, setMonths] = useState<AutocompleteOption[]>([])
  const [includeWeekends, setIncludeWeekends] = useState(false)

  const [rule, setRule] = useState<Rule>({
    fk_id_email_rules_frequency: 1,
    time: null,
    end_date: null
  })

  const weekdaysOptions = useMemo(() => {
    return lookupTables.weekDays
      .filter((item) => !weekdays.find((_) => _.id === item.id))
      .map((item) => ({ id: item.id, label: item.option }))
  }, [lookupTables, weekdays])

  const monthsOptions = useMemo(() => {
    return lookupTables.months
      .filter((item) => !months.find((_) => _.id === item.id))
      .map((item) => ({ id: item.id, label: item.option }))
  }, [lookupTables, months])

  const disabledAddButton = useMemo(() => {
    if (!rule.end_date || !rule.time) return true
    if (rule.fk_id_email_rules_frequency === 2 && !weekdays.length) return true
    if (rule.fk_id_email_rules_frequency === 3 && (!days.length || !months.length)) return true
  }, [rule, weekdays, days, months])

  const groupedRule: GroupedRule[] = useMemo(() => {

    const groupedIds = [...new Set(scheduleRules.map((item) => item.grouper_id))]

    return groupedIds.map((id) => {
      return {
        rules: scheduleRules.filter((item) => item.grouper_id === id),
        grouper_id: id
      }
    })
  }, [scheduleRules])

  const onAddScheduleRule = () => {
    let grouper_id = generateRandomID()

    if (rule.fk_id_email_rules_frequency === 3) {

      const newRules: EmailScheduleRule[] = days.sort((a, b) => a - b).flatMap((day) => {
        return months.map((_month) => ({
          day,
          end_date: rule.end_date,
          fk_id_email_rules_frequency: rule.fk_id_email_rules_frequency,
          fk_id_weekdays: null,
          time: rule.time,
          month: _month.id,
          grouper_id
        } as EmailScheduleRule))
      })

      setScheduleRules((_scheduleRules) => [..._scheduleRules, ...newRules])

    } else {
      let _weekDays = rule.fk_id_email_rules_frequency === 2 ? weekdays : (includeWeekends ? weekdaysOptions : weekdaysOptions.filter((_) => ![0, 6].includes(_.id)))

      const newRules = _weekDays.map((item) => {
        return {
          day: null,
          end_date: rule.end_date,
          fk_id_email_rules_frequency: rule.fk_id_email_rules_frequency,
          fk_id_weekdays: item.id,
          time: rule.time,
          month: null,
          grouper_id
        }
      })

      setScheduleRules((_scheduleRules) => [..._scheduleRules, ...newRules])
    }

    setRule({
      fk_id_email_rules_frequency: 1,
      time: null,
      end_date: null
    })
  }

  const onDeleteScheduleRule = (id: string) => {
    setScheduleRules((_scheduleRules) => {
      return _scheduleRules.filter((_) => _.grouper_id !== id)
    })
  }

  const renderComponent = () => {
    switch (rule.fk_id_email_rules_frequency) {
      case 1:
        return <>
          <Grid item xs={6.8}>
            <TextField
              select
              label="Daily"
              value="Every day"
              disabled
              fullWidth
            >
              <MenuItem value="Every day">Every day</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3.2}>
            <FormControlLabel
              control={
                <Checkbox
                  id='form-field-include-weekends'
                  checked={includeWeekends}
                  onChange={(e) => setIncludeWeekends(e.target.checked)}
                />}
              label={'Include weekends'}
            />
          </Grid>
        </>
      case 2:
        return <Grid item xs={10}>
          <Autocomplete
            id='form-weekday'
            multiple
            options={weekdaysOptions}
            value={weekdays}
            onChange={(e, newValue) => { setWeekdays(newValue) }}
            renderInput={(params) => <TextField {...params} label="Week Day" />}
            renderTags={(value) => (
              weekdays.length > 4 ?
                weekdays.map((item, index) => (index <= 3 ? <Chip key={index} label={index < 3 ? item.label : `${item.label} + ${weekdays.length - 4}...`} /> : null))
                :
                weekdays.map((item, index) => <Chip key={index} label={item.label} />)
            )}
          />
        </Grid>
      case 3:
        return <>
          <Grid item xs={6}>
            <Autocomplete
              id='form-months'
              multiple
              options={monthsOptions}
              value={months}
              onChange={(event, newValue) => setMonths(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Months" />
              )}
              renderTags={(value) =>
                months.length > 3 ?
                  months.map((item, index) => (index <= 2 ? <Chip key={index} label={index < 2 ? item.label : `${item.label} + ${months.length - 3}...`} /> : null))
                  :
                  months.map((item, index) => <Chip key={index} label={item.label} />)
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id='form-month-days'
              multiple
              options={Array.from({ length: 31 }, (_, i) => (i + 1))}
              value={days}
              onChange={(event, newValue) => setDays(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Month days" />
              )}
              getOptionLabel={(option) => String(option)}
              renderTags={(value) =>
                days.length > 1 ?
                  days.map((item, index) => (index <= 1 ? <Chip key={index} label={index < 1 ? item : `${item} + ${days.length - 2}...`} /> : null))
                  :
                  days.map((item, index) => <Chip key={index} label={item} />)
              }
            />
          </Grid>
        </>
      default: <Grid item xs={8}>
        <Autocomplete
          id='form-daily'
          multiple
          options={[{ id: 0, label: 'Every Day' }]}
          value={[{ id: 0, label: 'Every Day' }]}
          renderInput={(params) => <TextField {...params} label="Daily" />}
        />
      </Grid>
    }
  }

  const returnRuleDescription = (item: GroupedRule) => {
    //Daily
    if (item.rules[0].fk_id_email_rules_frequency === 1) {
      if (item.rules.length === 5) return 'From Monday to Friday'
      return 'Every day of week'
    }

    //Weekly
    if (item.rules[0].fk_id_email_rules_frequency === 2) {

      let selectedDaysOfWeek = item.rules.map((_) => _.fk_id_weekdays)

      let _selectedDaysOfWeek = lookupTables.weekDays
        .filter((_) => selectedDaysOfWeek.includes(_.id))
        .sort((a, b) => a.id - b.id)
        .map((day) => day.option)

      if (_selectedDaysOfWeek.length > 1) {
        const lastDay = _selectedDaysOfWeek.pop()
        return `Every ${_selectedDaysOfWeek.join(', ')} and ${lastDay}`
      } else {
        return `Every ${_selectedDaysOfWeek[0]}`
      }
    }

    //Monthly
    if (item.rules[0].fk_id_email_rules_frequency === 3) {

      let days = item.rules.map((_) => _.day).filter((value, index, arr) => arr.indexOf(value) === index)

      let monthsIds = item.rules.map((_) => _.month)

      let months = lookupTables.months.filter((_) => monthsIds.includes(_.id))

      let lastDay = days.pop()
      let _days = days.length > 1 ? `${days.map((day) => `${day}`).join(', ')} and ${lastDay}` : `${lastDay}`

      let lastMonth = months.pop()
      let _months = months.length > 1 ? `${months.map((month) => `${month.option}`).join(', ')} and ${lastMonth?.option}` : `${lastMonth?.option}`

      return `Every ${days.length > 1 ? 'days' : 'day'}  ${_days}, of ${_months}`
    }
  }

  useEffect(() => {
    setRule({
      fk_id_email_rules_frequency: 1,
      time: null,
      end_date: null
    })
    setScheduleRules([])
  }, [isRecurrentEmail, setScheduleRules])

  return <>
    <Grid item xs={2}>
      <TextField
        id='form-email-frequency'
        label='Frequency'
        select
        value={rule.fk_id_email_rules_frequency}
        onChange={(e) => setRule(rule => ({ ...rule, fk_id_email_rules_frequency: Number(e.target.value) }))}
      >
        {
          lookupTables.emailRulesFrequency.map((item, counter) => {
            return <MenuItem key={counter} value={item.id}>
              {item.option}
            </MenuItem>
          })
        }
      </TextField>
    </Grid>

    {renderComponent()}

    <Grid item xs={3}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          slotProps={{ textField: { id: 'form-field-end-date' } }}
          label='Until'
          format="dd-MMM-yy"
          value={rule.end_date ? DateTime.fromISO(rule.end_date) : null}
          onChange={(newValue) => setRule({ ...rule, end_date: newValue ? newValue.toISODate() : null })}
        />
      </LocalizationProvider>
    </Grid>

    <Grid item xs={3}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <TimePicker
          slotProps={{ textField: { id: 'form-field-time' } }}
          label='At'
          value={rule.time ? DateTime.fromISO(rule.time) : null}
          onChange={(newValue) => setRule({ ...rule, time: newValue ? newValue.toFormat('HH:mm') : null })}
          shouldDisableTime={(time, view) => view === 'minutes' && time.minute !== 0}
          viewRenderers={{
            hours: renderTimeViewClock,
          }}
        />
      </LocalizationProvider>
    </Grid>
    <Grid item xs={4} />
    <Grid item xs={2}>
      <Button
        id='button-schedule-add'
        onClick={() => onAddScheduleRule()}
        disabled={disabledAddButton}
      >
        ADD
      </Button>
    </Grid>

    <Grid item xs={12}>
      {
        groupedRule.length ?
          <Box sx={{
            maxHeight: 300,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#132F39',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#f2f2f2',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#0d1e27',
            },
            paddingRight: 1
          }}>
            {
              groupedRule.map((item) => (
                <Paper sx={{ background: '#f2f2f2', color: '#000', paddingY: 1, paddingX: 2, marginBottom: 1 }}>
                  <Grid container spacing={1} alignItems={'center'}>
                    <Grid item xs={11}>
                      <Grid container spacing={0.1}>
                        <Grid item xs={12}>
                          <Typography sx={{ color: '#132F39', fontWeight: 600 }}>{returnRuleDescription(item)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography sx={{ color: '#132F39', fontWeight: 600 }}>At {item.rules[0].time}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography sx={{ color: '#132F39', fontWeight: 600 }}>Untill {item.rules[0].end_date ? DateTime.fromISO(item.rules[0].end_date).toFormat('dd-LLL-yy') : ''}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => onDeleteScheduleRule(item.grouper_id)}
                        sx={{ color: '#132F39', background: 'rgba(19, 47, 57, 0.5)' }}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              ))
            }
          </Box>
          :
          <></>
      }
    </Grid>
  </>
}
