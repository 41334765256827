import { Container, Grid, Tab, Tabs } from "@mui/material"
import { Suspense, lazy, useState } from "react"
import PreLoader from "../components/PreLoader"

// import BaseCourses from "../components/academic/BaseCourses"
// import Classes from "../components/academic/Classes"
// import Courses from "../components/academic/Courses"
// import Sections from "../components/academic/Sections"
// import BaseLessons from "../components/academic/BaseLessons"
// import Header from "../components/header/Header"
// import Lessons from "../components/academic/Lessons"
// import RollCallSearch from "../components/academic/RollCallSearch"

const BaseCourses = lazy(() => import("../components/academic/BaseCourses"))
const Classes = lazy(() => import("../components/academic/Classes"))
const Courses = lazy(() => import("../components/academic/Courses"))
const Sections = lazy(() => import("../components/academic/Sections"))
const BaseLessons = lazy(() => import("../components/academic/BaseLessons"))
const Header = lazy(() => import("../components/header/Header"))
const Lessons = lazy(() => import("../components/academic/Lessons"))
const RollCallSearch = lazy(() => import("../components/academic/RollCallSearch"))
const Exams = lazy(() => import("../components/academic/Exams"))

export default function AcademicView() {
  const [tab, setTab] = useState<string>('baseCourses')

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const renderComponent = () => {
    switch (tab) {
      case 'baseCourses':
        return <BaseCourses />
      case 'courses':
        return <Courses />
      case 'sections':
        return <Sections />
      case 'classes':
        return <Classes />
      case 'baseLessons':
        return <BaseLessons />
      case 'lessons':
        return <Lessons />
      case 'roll-call':
        return <RollCallSearch />
      case 'exams':
        return <Exams />
      default:
        return <BaseCourses />
    }
  }

  return <Suspense fallback={<PreLoader />}>
    <Container maxWidth='xl'>
      <br />
      <Grid container spacing={1} maxHeight={'100vh'}>
        <Header title={'Academic'} name={''} />
        <Grid item xs={12}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab id='academic-tab-base-courses' label='Base Courses' value='baseCourses' />
            <Tab id='academic-tab-courses' label='Courses' value='courses' />
            <Tab id='academic-tab-sections' label='Sections' value='sections' />
            <Tab id='academic-tab-classes' label='Classes' value='classes' />
            <Tab id='academic-tab-base-lessons' label='Base Lessons' value='baseLessons' />
            <Tab id='academic-tab-lessons' label='Lessons' value='lessons' />
            <Tab id='academic-tab-roll-calls' label='Roll Call' value='roll-call' />
            <Tab id='academic-tab-exams' label='Exams' value='exams' />
          </Tabs>
        </Grid>
        {renderComponent()}
      </Grid>
    </Container>
  </Suspense>
}
