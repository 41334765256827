import axios from "axios"
import { useCallback, useEffect, useState } from "react"
import { Panel } from "../_billings/AssignBundles"
import { Grid, Tooltip } from "@mui/material"
import { BodyContainer, BodyRow, Cell, HeaderRow, Overhead, Status, Table } from "wed-components"
import { toast } from "react-toastify"
import { Groups, Lock, Person, Public } from "@mui/icons-material"

type Props = {
  panel: Panel,
  year: number
}

type Data = {
  bundles: {
    bundle_application_unique_id: string
    bundle_name: string
    voucher_amount: number
    voucher_type_tag: string
  }[]
  courseProgression: {
    affiliation_course_progression_name: string | null
    affiliation_course_progression_tag: string | null
    base_course_credit_default: number
    base_course_name: string
    fk_id_affiliation_course_progression: number | null
    fk_id_voucher_type: number
    id?: number
    is_group_classes: boolean | null
    is_private: boolean | null
    is_self_paced: boolean | null
    term_name: string
    voucher_quantity: number
    voucher_type_tag: string
  }[]
  vouchers: {
    total_vouchers: number
    voucher_type_tag: string
  }[]
}

export default function AssignBundlesDetails({ panel, year }: Props) {
  // General
  const columnWidth = [1, 1, 4, .8, .9]
  // Data
  const [data, setData] = useState<Data | null>(null)

  const refreshTable = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/assign-bundles?fk_id_student=${panel.fk_id_student}&year=${year}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      if (year === 2025) {
        const { data: requestCourseProgression } = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_REQUESTS_URL}/get-requested-course-progression?fk_id_student=${panel.fk_id_student}&year=${year}`,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_REQUEST_TOKEN}`
          }
        })

        console.log(requestCourseProgression)

        setData(() => ({
          bundles: data.bundles,
          courseProgression: [...data.courseProgression, ...requestCourseProgression],
          vouchers: data.vouchers
        }))
      }
      else {
        setData(data)
      }
    } catch (err) {
      console.log(err)
      toast.error('Could not fetch the Cashflow and Course Progression.')
    }
  }, [panel, year])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  return <Grid container>
    <Grid item xs={12}>
      <Table>
        <Overhead style={{ height: 60 }}>
          <Cell flexGrow>
            <p style={{ fontSize: 20 }}>
              Billings for {year}
            </p>
          </Cell>
        </Overhead>
        <HeaderRow>
          <Cell flexGrow>
            <p>
              Bundle
            </p>
          </Cell>
          <Cell>
            <p>
              Vouchers
            </p>
          </Cell>
        </HeaderRow>
        <BodyContainer>
          {
            data?.bundles.map((_, index) => <BodyRow key={index}>
              <Cell flexGrow>
                <p>
                  {_.bundle_name}
                </p>
              </Cell>
              <Cell>
                <p>
                  {_.voucher_amount} ({_.voucher_type_tag})
                </p>
              </Cell>
            </BodyRow>
            )
          }
        </BodyContainer>
        <HeaderRow>
          <Cell flexGrow>
            <p>Total: {data?.vouchers.map((_) => `${_.total_vouchers} (${_.voucher_type_tag})`).join(',')}</p>
          </Cell>
        </HeaderRow>
      </Table>
    </Grid>
    <Grid item xs={12}>
      <Table>
        <Overhead style={{ height: 60 }}>
          <Cell flexGrow>
            <p style={{ fontSize: 20 }}>
              Course Progression for {year}
            </p>
          </Cell>
        </Overhead>
        <HeaderRow>
          <Cell width={columnWidth[0]}>
            <p>
              ID
            </p>
          </Cell>
          <Cell width={columnWidth[1]}>
            <p>
              Term
            </p>
          </Cell>
          <Cell flexGrow>
            <p>
              Base Course
            </p>
          </Cell>
          <Cell width={columnWidth[3]}>
            <p>
              Credits
            </p>
          </Cell>
          <Cell width={columnWidth[4]}>
            <p>
              Vouchers
            </p>
          </Cell>
          <Cell width={1.2}>
            <p>
              Details
            </p>
          </Cell>
        </HeaderRow>
        <BodyContainer>
          {
            data?.courseProgression.map((_, index) => <BodyRow key={index}>
              <Cell width={columnWidth[0]}>
                {_.id ? <p>{_.id}</p> : <Status color='blue'>Request</Status>}
              </Cell>
              <Cell width={columnWidth[1]}>
                <p>
                  {_.term_name}
                </p>
              </Cell>
              <Cell flexGrow>
                <p>
                  {_.base_course_name}
                </p>
              </Cell>
              <Cell width={columnWidth[3]}>
                <p>
                  {_.base_course_credit_default}
                </p>
              </Cell>
              <Cell width={columnWidth[4]}>
                <p>
                  {_.voucher_quantity} ({_.voucher_type_tag})
                </p>
              </Cell>
              <Cell width={1.2}>
                {
                  _.fk_id_affiliation_course_progression ?
                    // Course with WED
                    <Tooltip title={`This course will be taken with ${_.affiliation_course_progression_name}`}>
                      <Public id='icon-course-with-wed' sx={{ marginY: 'auto' }} />
                    </Tooltip>
                    :
                    null
                }
                {
                  _.is_private ?
                    // Private
                    <Tooltip title='Private classes'>
                      <Lock id='icon-private-classes' sx={{ marginY: 'auto' }} />
                    </Tooltip>
                    :
                    null
                }
                {
                  _.is_self_paced ?
                    // Self paced
                    <Tooltip title='Self-paced course'>
                      <Person id='icon-self-paced' sx={{ marginY: 'auto' }} />
                    </Tooltip>
                    :
                    null
                }
                {
                  _.is_group_classes ?
                    // Group classes
                    <Tooltip title='Group classes'>
                      <Groups id='icon-group-class' sx={{ marginY: 'auto' }} />
                    </Tooltip>
                    :
                    null
                }
              </Cell>
            </BodyRow>
            )
          }
        </BodyContainer>
        <HeaderRow>
          <Cell flexGrow>
            <p>
              Credits: {data?.courseProgression.reduce((prev, curr) => prev += curr.base_course_credit_default, 0)}
            </p>
          </Cell>
          <Cell flexGrow>
            <p style={{ flexDirection: 'row' }}>Vouchers: {
              data?.courseProgression
                .reduce((prev: { voucher_type_tag: string, voucher_quantity: number }[], curr) => {
                  const index = prev.findIndex((_) => _.voucher_type_tag === curr.voucher_type_tag)
                  if (index > -1) {
                    prev[index].voucher_quantity += curr.voucher_quantity
                  }
                  else {
                    prev.push({
                      voucher_type_tag: curr.voucher_type_tag,
                      voucher_quantity: curr.voucher_quantity
                    })
                  }
                  return prev
                }, [])
                .map((_) => `${_.voucher_quantity} (${_.voucher_type_tag})`)
                .join(', ')
            }</p>
          </Cell>
        </HeaderRow>
      </Table>
    </Grid>
  </Grid>
}
