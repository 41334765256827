import axios from 'axios'
import { toast } from "react-toastify"
import { Button, Grid, MenuItem, TextField } from '@mui/material'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { RightSidePanel } from 'wed-components'
import AutomatedServiceForm from './AutomatedServiceForm'
import AutomatedServicesSchedulesUpdatesTable from './AutomatedServicesTable'
import AutomatedServiceAllLastUpdate from './AutomatedServiceLastUpdate'
import { NewEditButtons } from '../assets/NewEditButtons'
import { toastError, toastSuccess } from '../assets/customToasts'
import { LookupTablesContext } from '../../context/LookupTablesContext'
import { Visibility } from '@mui/icons-material'

export type Form = {
  id?: number
  panel?: 'new' | 'edit' | 'lastUpdates'
  show: boolean
}

type Filters = {
  id: number | ''
  trigger_name: string
  automated_service_name: string
}

export type AutomatedServices = {
  id: number
  automated_service_name: string
  path: string
  valid_from: string
  valid_to: string
  lastUpdates: AutomatedServicesLastUpdates[]
  serviceSchedule: AutomatedServicesSchedules[]
}

export type AutomatedServicesLastUpdates = {
  fail: boolean
  fk_id_automated_service: number
  last_update: string
  fk_id_trigger: number
}

export type AutomatedServicesSchedules = {
  fk_id_trigger: number
  fk_id_automated_services: number
  trigger_name: string
  service_last_update: string
  fail: boolean
  description: string
}

export default function AutomatedServices() {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // General
  const [form, setForm] = useState<Form>({
    show: false
  })
  // Data
  const [data, setData] = useState<AutomatedServices[]>([])
  // Filters
  const [filters, setFilters] = useState<Filters>({
    id: '',
    trigger_name: '',
    automated_service_name: ''
  })
  // useMemos
  const selectedService = useMemo(() => data.find((_) => _.id === form.id)!, [data, form])

  const refreshTableServices = async () => {
    const _toast = toast('Fetching services...', { isLoading: true })

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_NOTIFIER_URL}/automated-services`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_NOTIFIER_TOKEN}`
        }
      })

      console.log(data)

      setData(data)

      toastSuccess(_toast, 'Fetch!')
    } catch (error) {
      console.log(error)
      toastError(_toast, 'Could not fetch services.')
    }
  }

  const deleteSchedule = async () => {
    const _toast = toast('Deleting service...', { isLoading: true })

    try {
      await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_NOTIFIER_URL}/automated-services/${selectedService?.id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_NOTIFIER_TOKEN}`
        }
      })

      toastSuccess(_toast, 'Successfully deleted service.')

      refreshTableServices()
    } catch (error) {
      console.log(error)
      toastError(_toast, 'Could not delete service.')
    }
  }

  useEffect(() => {
    refreshTableServices()
  }, [])

  const renderPanel = () => {
    switch (form.panel) {
      case 'new':
        return <>
          <AutomatedServiceForm
            selectedService={selectedService}
            form={form}
            setForm={setForm}
            refreshTableServices={refreshTableServices}
          />
        </>
      case 'edit':
        return <>
          <AutomatedServiceForm
            selectedService={selectedService}
            form={form}
            setForm={setForm}
            refreshTableServices={refreshTableServices}
          />
          <AutomatedServiceAllLastUpdate
            form={form}
            selectedService={selectedService}
          />
        </>
      case 'lastUpdates':
        return <>
          <AutomatedServiceAllLastUpdate
            form={form}
            selectedService={selectedService}
          />
        </>
      default:
        <></>
    }
  }

  const getPanelTitle = () => {
    switch (form.panel) {
      case 'new':
        return 'New Automated Service'
      case 'edit':
        return 'Edit Automated Service'
      case 'lastUpdates':
        return `Last updates of: ${selectedService?.automated_service_name}`
      default:
        return 'New Automated Service'
    }
  }

  const idFilter = useCallback((row: AutomatedServices) => {
    switch (filters.id) {
      case '':
        return true
      default:
        return row.id === Number(filters.id)
    }
  }, [filters.id])

  const serviceNameFilter = useCallback((row: AutomatedServices) => {
    const re = new RegExp(filters.automated_service_name.toLowerCase(), 'i')

    switch (filters.automated_service_name) {
      case '':
        return true
      default:
        return re.test(row.automated_service_name.toLowerCase())
    }
  }, [filters.automated_service_name])

  const triggerNameFilter = useCallback((row: AutomatedServices) => {

    if (filters.trigger_name === '') return true

    const re = new RegExp(filters.trigger_name.toLowerCase(), 'i')
    const triggers_name = row.serviceSchedule.map((_) => _.trigger_name)

    if (triggers_name.map((triggerName) => re.test(triggerName.toLowerCase())).includes(true)) return true

  }, [filters.trigger_name])

  return (
    <>
      <NewEditButtons
        _new={{ label: 'New Service', onClick: () => setForm({ show: true, panel: 'new' }) }}
        _edit={{ disabled: !form.id, onClick: () => setForm({ ...form, show: true, panel: 'edit' }) }}
        _delete={{ disabled: !form.id, onClick: () => deleteSchedule() }}
      />
      <Grid item xs={1.25}>
        <Button
          id='button-details'
          startIcon={<Visibility />}
          onClick={() => setForm({ ...form, show: true, panel: 'lastUpdates' })}
          disabled={!form.id}
        >
          Details
        </Button>
      </Grid>
      {/* Spacer */}
      <Grid item xs={1.75} />
      {/* Filters */}
      <Grid item xs={2}>
        <TextField
          id='search-filter-trigger-name'
          label='Trigger name'
          value={filters.trigger_name}
          onChange={(e) => setFilters({ ...filters, trigger_name: e.target.value })}
          select
        >
          {
            lookupTables.triggers.map((_trigger, index) => <MenuItem key={index} id={`trigger-option-${_trigger.id}`} value={_trigger.id}>{_trigger.option}</MenuItem>)
          }
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <TextField
          id='search-filter-service-name'
          label='Service name'
          value={filters.automated_service_name}
          onChange={(e) => setFilters({ ...filters, automated_service_name: e.target.value })}
        />
      </Grid>

      <RightSidePanel
        state={form.show}
        close={() => setForm({ show: false })}
        title={getPanelTitle() || ''}
      >
        {
          form.show ?
            renderPanel()
            :
            <></>
        }
      </RightSidePanel>

      <Grid item xs={12} maxHeight={'70vh'}>
        <AutomatedServicesSchedulesUpdatesTable
          setForm={setForm}
          form={form}
          data={data.filter(idFilter).filter(serviceNameFilter).filter(triggerNameFilter)}
        />
      </Grid>
    </>
  )
}
