import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from "wed-components"
import { Panel, TemplateWithRules } from "./types"
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@mui/material"
import { MoreVert, Visibility, Clear } from "@mui/icons-material"
import { Dispatch, SetStateAction } from "react"

type Props = {
    data: TemplateWithRules[]
    setOpenModal: Dispatch<SetStateAction<boolean>>
    setPanel: Dispatch<SetStateAction<Panel>>
    selectedRow: { button?: HTMLElement, id: number } | null,
    setSelectedRow: Dispatch<SetStateAction<{ button?: HTMLElement, id: number } | null>>
}

export default function EmailScheduleRulesTable({ data, selectedRow, setSelectedRow, setPanel, setOpenModal }: Props) {
    const columnWidth = [0.6, 1]

    return <Table>
        <HeaderRow>
            <Cell width={columnWidth[0]}><p>ID</p></Cell>
            <Cell flexGrow><p>Template name</p></Cell>
            <Cell width={columnWidth[1]}><p>Frequency</p></Cell>
            <Cell width={1.2}><p></p></Cell>
        </HeaderRow>
        <BodyContainer>
            {
                data.length ?
                    data.map((_row, index) => (
                        <BodyRow key={_row.id}>
                            <Cell width={columnWidth[0]}>
                                <p>{_row.id}</p>
                            </Cell>
                            <Cell flexGrow>
                                <p>{_row.subject}</p>
                            </Cell>
                            <Cell width={columnWidth[1]}>
                                <p>
                                    {_row.rules.find((_rule) => _rule.fk_id_email_rules_frequency === 1) ? <span>Daily </span> : null}
                                    {_row.rules.find((_rule) => _rule.fk_id_email_rules_frequency === 2) ? <span>Weekly </span> : null}
                                    {_row.rules.find((_rule) => _rule.fk_id_email_rules_frequency === 3) ? <span>Monthly </span> : null}
                                </p>
                            </Cell>
                            <Cell width={columnWidth[0]}>
                                <IconButton id={`button-menu-schedule-rule-${_row.rules[0].id}`} onClick={(e) => setSelectedRow({ button: e.currentTarget, id: _row.id })}>
                                    <MoreVert />
                                </IconButton>
                            </Cell>
                            <Menu
                                id={`menu-schedule-rules-${_row.id}`}
                                open={Boolean(selectedRow?.button)}
                                onClose={() => setSelectedRow(null)}
                                anchorEl={selectedRow?.button}
                            >
                                <MenuList>
                                    <MenuItem
                                        id='menu-option-visualize'
                                        onClick={() => {
                                            setPanel({ id: selectedRow?.id, show: true })
                                            setSelectedRow(prev => ({ id: prev?.id || 0 }))
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Visibility />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Visualize
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem
                                        id='menu-option-delete'
                                        onClick={() => {
                                            setOpenModal(true)
                                            setSelectedRow(prev => ({ id: prev?.id || 0 }))
                                        }}
                                    >
                                        <ListItemIcon >
                                            <Clear />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Delete
                                        </ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </BodyRow>
                    ))
                    :
                    <BodyRow><Cell width={columnWidth[1]}><p>No schedulings</p></Cell></BodyRow>
            }
        </BodyContainer >
    </Table >
}
