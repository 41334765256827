import axios from "axios"
import { toast } from "react-toastify"
import { useContext, useMemo, useState } from "react"
import { Grid, TextField, Button, Autocomplete } from "@mui/material"
import { AutomatedServices, Form } from "./AutomatedServices"
import { SaveAlt } from "@mui/icons-material"
import { LookupTablesContext } from "../../context/LookupTablesContext"
import { toastError, toastSuccess } from "../assets/customToasts"

type AutomatedServiceFormProps = {
  form: Form
  setForm: React.Dispatch<React.SetStateAction<Form>>
  refreshTableServices: () => Promise<void>
  selectedService: AutomatedServices
}

type Info = {
  automated_service_name: string,
  path: string,
  triggers: {
    id: number,
    label: string
  }[]
}

export default function AutomatedServiceForm({ setForm, refreshTableServices, selectedService }: AutomatedServiceFormProps) {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // Data
  const [info, setInfo] = useState<Info>(
    selectedService ?
      {
        automated_service_name: selectedService.automated_service_name,
        path: selectedService.path,
        triggers: selectedService.serviceSchedule.map((_) => ({
          id: _.fk_id_trigger,
          label: _.trigger_name
        }))
      }
      :
      {
        automated_service_name: '',
        path: '',
        triggers: []
      }
  )
  // useMemos
  const selectTriggerOptions = useMemo(() => lookupTables.triggers
    .map((trigger) => ({ id: trigger.id, label: trigger.option }))
    .filter((trigger) => !info.triggers.find((_) => _.id === trigger.id)), [info.triggers, lookupTables])

  const onSave = async () => {
    const _toast = toast(selectedService ? 'Updating Automated Service' : 'Saving Automated Service', { toastId: 'toast-service-form-save', isLoading: true })

    try {
      await axios({
        method: selectedService ? 'PUT' : 'POST',
        url: selectedService ? `${process.env.REACT_APP_DIPLOMAT_URL}/automated-services/${selectedService.id}` : `${process.env.REACT_APP_DIPLOMAT_URL}/automated-services`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_DIPLOMAT_TOKEN}`
        },
        data: {
          ...info,
          triggers: info.triggers.map((_) => _.id)
        }
      })

      setForm({ show: false })

      refreshTableServices()

      toastSuccess(_toast, 'Successfully saved the automated service.')
    } catch (error) {
      console.log(error)
      toastError(_toast, 'Could not save the automated service.')
    }
  }

  return <>
    <Grid container>
      <Grid item xs={6}>
        <TextField
          id='form-field-service-name'
          label='Service Name'
          value={info.automated_service_name}
          onChange={(e) => setInfo({ ...info, automated_service_name: e.target.value })}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          id='form-field-path'
          label='Path'
          value={info.path}
          onChange={(e) => setInfo({ ...info, path: e.target.value })}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          id='form-field-trigger'
          options={selectTriggerOptions}
          value={info.triggers}
          onChange={(e, newValue) => setInfo({ ...info, triggers: newValue })}
          disableCloseOnSelect
          multiple
          renderInput={(params) => <TextField {...params} label='Triggers' />}
        />
      </Grid>


      <Grid item xs={3}>
        <Button
          id='button-new-service'
          startIcon={<SaveAlt />}
          onClick={onSave}
          sx={{ height: 56 }}
          disabled={!info.automated_service_name || !info.path || !info.triggers?.length}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  </>
}
